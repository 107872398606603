<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
      id="kt_login"
    >

      <!--end::Content-->
      <div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-15 img"
        style="">
        <!-- <div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-15 img"
        :style="{ backgroundImage: `url(${backgroundImage})` }"> -->
       <!-- <div class=" d-flex align-items-center justify-content-center">
            <img class="logo" src="media/logos/logo-main.png"  />
          </div> -->
      </div>
      <!--begin::Content-->
      <div
        class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-scroll"
      >
        <div class="d-flex flex-column-fluid flex-center mt-3 mt-lg-0">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "auth",
  methods: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/logo-main.png";
      // return process.env.BASE_URL + "media/bg/login-bg.svg";
    }
  }
};
</script>
<style lang="scss" scoped>

@media only screen and (max-width: 600px) {
  .img {
    height: 46%;
    position: relative;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .logo{
    width: 80% !important;
  }
}
.logo {
    width: 50%;
}
#kt_login .login-aside{
  // background: #FF6633;
  background-image: url("./../../layout/header/imgs/ramadan-login.png");
  // background-position: center;
  // background: url('./../../layout/header/imgs/header-mobile.png');

  background-position: center;
  @media only screen and (max-width: 785px) {
    background: url('./../../layout/header/imgs/ramadan-mobile.png');
    background-position: center;
  }
 
}

</style>